<template>
  <v-app id="inspire">
    <v-container fluid style="height: 100vh; width: 100%; margin-top: 50px">
      <v-container fluid>
        <v-row class="ma-2 my-4 flex">
          <h4 style="margin: auto">Upload manual de arquivos XML</h4>
          <v-spacer></v-spacer>
        </v-row>

        <v-card>
          <v-card-text>
            <br />
            <br />
            <!-- Add the "multiple" prop so users can select more than one file -->
            <v-file-input
              v-model="selectedFiles"
              label="Upload Files"
              outlined
              multiple
              @change="handleFileUpload"
            ></v-file-input>
          </v-card-text>

          <!-- <v-card-text>
            {{ JSON.stringify({ selectedFiles }) }}
          </v-card-text> -->

          <v-card-text>
            <!-- {{ JSON.stringify({ filesData }) }} -->
            <b>Arquivos XML</b>
            <p v-for="(file, index) in filesData" :key="index">
              {{ file.name }}
              <v-progress-circular
                v-if="file.loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <span style="color: green" v-if="file.uploaded">OK</span>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="uploadFiles">Upload</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="grey" @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import functions from "../../../../libraries/strapi/functions";

export default {
  name: "XmlImport",
  props: ["value", "processId", "process"],
  data() {
    return {
      files: [],
      // changed from a single file to an array for multiple files
      selectedFiles: [],
      // Array to hold objects in the form { name, base64 }
      filesData: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    dialog(val) {},
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },

    handleFileUpload(files) {
      // Clear any previous file data
      this.filesData = [];
      if (!files || !files.length) return;

      // For each file, read its content as base64
      files.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.name?.includes(".xml")) {
            this.filesData.push({
              name: file.name,
              base64: reader.result.split(",")[1], // Remove `data:...;base64,` prefix
              loading: false,
              uploaded: false,
            });
          }
        };
        reader.onerror = (error) => console.error("Error reading file:", error);
      });
    },
    async uploadFiles() {
      // Ensure that files have been selected and processed
      if (!this.filesData.length || !this.selectedFiles.length) return;

      try {
        // Loop over each file and post it to the API
        for (const fileData of this.filesData) {
          this.filesData = this.filesData.map((el) =>
            fileData.name == el.name ? { ...el, loading: true } : el
          );
          await axios.post(
            `${process.env.VUE_APP_STRAPI_URL}/api/offer/importXmlToNfse`,

            {
              // filename: fileData.name,
              // base64: fileData.base64,
              // process: this.process?.id,
              // creator: this.user.id,
              fileName: fileData.name,
              fileContent: fileData.base64,
              fileContentType: "xml",
            },

            {
              headers: {
                Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
                "Content-Type": "application/json",
              },
            }
          );

          this.filesData = this.filesData.map((el) =>
            fileData.name == el.name
              ? { ...el, loading: false, uploaded: true }
              : el
          );
        }

        // Clear selected files and file data arrays
        this.selectedFiles = [];
        this.filesData = [];
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
