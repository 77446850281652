<template>
  <v-container style="height: 100%" fluid>
    <v-snackbar v-model="snackbarShow" top right :color="snackbarColor">
      <span style="font-size: x-large; line-height: 1.2em">{{
        snackbarText
      }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbarShow = false">
          <span style="font-size: x-large; color: white">X</span>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogShow" width="600px">
      <v-card>
        <v-toolbar class="primary" dark>
          <span class="">Confirma envio?</span>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="dialogShow = false"> X </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense style="">
            <!-- <template v-slot:default> -->
            <tbody>
              <tr>
                <td>Ítens selecionados</td>
                <td>{{ selectedInstallments?.length }}</td>
              </tr>
              <tr>
                <td>Prazo médio ponderado</td>
                <td>{{ averageDaysUntilDue }} dias</td>
              </tr>

              <tr>
                <td>Valor total</td>
                <td>
                  <span
                    v-money-format="totalPrice(selectedInstallments)"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>Valor de aquisição</td>
                <td>
                  <span
                    v-money-format="totalAcquisitionPrice(selectedInstallments)"
                  ></span>
                </td>
              </tr>
            </tbody>
            <!-- </template> -->
          </v-simple-table>
        </v-card-text>
        <v-card-text v-if="installmentsWithNoFile?.length > 0">
          <p style="color: red">Existem notas sem arquivo XML:</p>
          <p style="color: red">
            {{
              installmentsWithNoFile?.map((el) => el.invoiceNumber)?.join(",")
            }}
          </p>
        </v-card-text>
        <v-card-actions class="" v-if="installmentsWithNoFile?.length == 0">
          <v-row class="mx-5">
            <v-btn color="red" @click="dialogShow = false">Não</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              @click="sendinstallmentsBackend"
              :disabled="sending"
            >
              <v-progress-circular
                v-show="sending"
                indeterminate
                color="white"
              ></v-progress-circular>
              Enviar via servidor</v-btn
            >
          </v-row>
        </v-card-actions>
        <hr class="my-10" />

        <v-card-title> </v-card-title>
      </v-card>
    </v-dialog>

    <!-- <td>{{ item.seller?.name }}</td>
    <td>{{ item.sponsorName }}</td> -->

    <v-dialog v-model="offersToCheckShow" width="1200px">
      <v-card>
        <v-toolbar class="primary" dark>
          <span class="">Checar notas já enviadas</span>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="offersToCheckShow = false">
            X
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container v-if="offersToCheck?.length">
            <p>Revisar notas fiscais já enviadas</p>
            <v-card
              v-for="offerToCheck in offersToCheck"
              :key="offerToCheck.id"
            >
              <v-toolbar style="font-size: large; background-color: azure">
                Nota {{ offerToCheck.invoiceNumber
                }}<v-spacer> </v-spacer> Sponsor:
                {{ offerToCheck.sponsorName }} <v-spacer> </v-spacer>

                <span v-money-format="offerToCheck.paymentValue"></span>
              </v-toolbar>
              <v-card-text>
                <v-row class="pa-2" style="background-color: lightgray"
                  ><p style="">Parcelas</p>
                </v-row>
                <v-row
                  class="pa-2"
                  v-for="(installment, index) in offerToCheck.installments"
                  :key="index"
                >
                  <v-spacer> </v-spacer>
                  <p>Parcela {{ index + 1 }}:</p>
                  <v-spacer> </v-spacer>
                  <v-spacer> </v-spacer>
                  <p>
                    Vencimento
                    <span v-kanastra-date-format="installment.dueDate"></span>
                  </p>
                  <v-spacer></v-spacer>
                  <p>
                    Valor
                    <span v-money-format="installment.amount"></span>
                  </p>
                </v-row>
                <v-container class="pa-2" style="background-color: gray"
                  ><p style="color: white">Histórico de envios</p>
                  <v-card
                    fluid
                    v-for="(oldOffer, indexOldOffer) in offerToCheck.oldOffers"
                    :key="indexOldOffer"
                  >
                    <div v-if="oldOffer?.kanastraJson?.created_at">
                      <v-toolbar style="font-size: large">
                        Nota enviada em:
                        <span
                          v-datetime-format="oldOffer?.kanastraJson.created_at"
                        ></span>
                        <v-spacer></v-spacer>
                        Valor:
                        <span
                          v-money-format="oldOffer?.kanastraJson.payment_value"
                        ></span>
                      </v-toolbar>

                      <v-card-text v-if="oldOffer?.kanastraJson?.installments">
                        <v-row
                          class="pa-2"
                          v-for="(
                            installmentOldOffer, indexInstallmentOldOffer
                          ) in oldOffer?.kanastraJson?.installments"
                          :key="indexInstallmentOldOffer"
                        >
                          <v-spacer> </v-spacer>
                          <p>Parcela {{ indexInstallmentOldOffer + 1 }}:</p>
                          <v-spacer> </v-spacer>
                          <v-spacer> </v-spacer>
                          <p>
                            Valor presente
                            <span
                              v-money-format="
                                installmentOldOffer.present_amount
                              "
                            ></span>
                          </p>
                          <v-spacer></v-spacer>
                          <p>
                            Valor futuro
                            <span
                              v-money-format="installmentOldOffer.future_amount"
                            ></span>
                          </p>
                        </v-row>
                      </v-card-text>
                    </div>
                  </v-card>
                </v-container>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-text> Continuar? </v-card-text>

        <v-card-actions>
          <v-row class="mx-5">
            <v-btn color="red" @click="offersToCheckShow = false">Não</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              @click="continueSendinstallmentsBackend"
              :disabled="sending"
            >
              <v-progress-circular
                v-show="sending"
                indeterminate
                color="white"
              ></v-progress-circular>
              Sim</v-btn
            >
          </v-row>
        </v-card-actions>
        <br />
        <br />
      </v-card>
    </v-dialog>
    <!-- <tr v-for="item in selectedInstallments" :key="item.id"> -->
    <v-container
      fluid
      style="
        margin-top: 20px;
        margin-bottom: 300px;
        display: flex;
        flex-direction: column;
        align-items: normal;
      "
    >
      <v-row>
        <v-col cols="2">
          <v-text-field
            label="Taxa_(%)"
            v-model="monthlyInterestEdit"
            v-currency
            currency="BRL"
            locale="pt-BR"
            @blur="editMonthlyInterest"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-2 my-4 flex">
        <h4 style="margin: auto">Buscar parcelas</h4>
        <v-spacer></v-spacer>
      </v-row>

      <v-row class="mx-5">
        <v-col cols="3" class="">
          <v-select
            :items="sellerNames"
            v-model="filters.chipSeller"
            label="Seller"
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-text-field
            class="pb-2"
            label="Sponsor"
            v-model="filters.sponsorName"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="">
          <v-text-field
            class="pb-2"
            label="Número da nota"
            v-model="filters.offerExternalId"
            @blur="doSearch"
          ></v-text-field>
        </v-col>

        <v-col cols="2" class="">
          <v-btn class="primary" @click="doMainSearch">Buscar</v-btn>
        </v-col>
      </v-row>

      <v-container fluid>
        <v-progress-circular
          v-show="loadingInstallments"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-data-table
          v-if="!loadingInstallments"
          :headers="headers"
          :items="installments"
          item-key="name"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
          ref="dataTable"
          @click:row="rowClicked"
        >
          <template v-slot:item.select="{ item }">
            <!-- <v-checkbox
              :value="isSelected(item)"
              @change="updated(item, item.selected)"
            ></v-checkbox>
            <span>{{ item.selected }}</span>
            <span>{{ isSelected(item) }}</span> -->
            <v-icon
              v-if="isSelected(item)"
              style="color: green; font-size: xx-large"
              >mdi-check-circle-outline
            </v-icon>
            <v-icon v-else style="color: black; font-size: xx-large"
              >mdi-circle-outline
            </v-icon>
          </template>
          <template v-slot:item.seller="{ item }">
            <span
              >{{ item.seller?.name }} ({{
                item.offer?.fileJson?.nfeProc?.NFe?.infNFe?.emit?.CNPJ ??
                item.offer?.fileJson?.NFe?.infNFe?.emit?.CNPJ
              }})</span
            >
          </template>

          <template v-slot:item.paymentDate="{ item }">
            <span v-date-format="item.paymentDate"></span>
          </template>
          <template v-slot:item.installmentDueDate="{ item }">
            <span v-date-format="item.installmentDueDate"></span>
          </template>
          <template v-slot:item.installmentAmount="{ item }">
            <span v-money-format="item.installmentAmount"></span>
          </template>

          <template v-slot:item.preCalculatedAcquisitionPrice="{ item }">
            <span v-money-format="item.preCalculatedAcquisitionPrice"></span>
          </template>

          <template v-slot:item.status="{ item }">
            {{ item.offer?.kanastraStatus }}
          </template>

          <template v-slot:item.hasFile="{ item }">
            {{ item.offer?.fileContent ? "Sim" : "Não" }}
          </template>

          <!-- Customizing the row style based on item data -->
        </v-data-table>
      </v-container>
      <div
        style="
          position: fixed;
          width: 96%;
          left: 60px;
          z-index: 100;
          bottom: 0px;
          background-color: white;
        "
        :class="{ divExpanded: isExpanded, divCollapsed: !isExpanded }"
      >
        <v-toolbar @click="toggleExpand" style="height: 55px" color="#00C6C0">
          <p style="color: white">Resumo</p>

          <v-spacer></v-spacer>
          <v-btn mb-1 color="transparent">
            <v-icon
              style="
                color: white;

                cursor: pointer;
                font-size: large;
              "
              v-if="isExpanded"
              >mdi-chevron-down
            </v-icon>
            <v-icon
              style="
                color: white;

                cursor: pointer;
                font-size: large;
              "
              v-if="!isExpanded"
              >mdi-chevron-up
            </v-icon>
          </v-btn>
        </v-toolbar>
        <div
          v-if="this.selectedInstallments?.length"
          style="
            background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            padding: 15px;
          "
        >
          <p style="font-size: 20px">
            {{ selectedInstallments?.length }} Selecionados
          </p>

          <p
            class="px-5"
            style="font-size: 20px"
            :class="{ red: averageDaysUntilDue > maxAverageDaysUntilDue }"
          >
            Prazo médio: {{ averageDaysUntilDue }}
          </p>
          <p style="font-size: 20px">
            Valor total
            <span v-money-format="totalPrice(selectedInstallments)"></span>
          </p>
          <p style="font-size: 20px">
            Valor de aquisição
            <span
              v-money-format="totalAcquisitionPrice(selectedInstallments)"
            ></span>
          </p>

          <v-btn color="primary" @click="dialogShow = true">Enviar</v-btn>
        </div>

        <div
          v-if="this.selectedInstallments?.length"
          style="overflow: scroll; height: 100%; margin: 20px"
        >
          <v-simple-table dense style="margin-bottom: 200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Remover</th>
                  <th class="text-left">Offer External Id</th>
                  <th class="text-left">Nota fiscal</th>
                  <th class="text-left">Seller</th>
                  <th class="text-left">Sponsor</th>
                  <th class="text-left">Sponsor Doc.</th>
                  <th class="text-left">Valor total (R$)</th>
                  <th class="text-left">Data do pagamento</th>
                  <th class="text-left">Parcelas</th>
                  <th class="text-left">Id da Parcela</th>
                  <th class="text-left">Data de vencimento</th>
                  <th class="text-left">Prazo dias</th>
                  <th class="text-left">Valor(R$)</th>
                  <th class="text-left">Valor arquisição(R$)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in selectedInstallments" :key="item.id">
                  <td>
                    <v-icon @click="remove(item)">mdi-delete</v-icon>
                  </td>
                  <td>{{ item.offerExternalId }}</td>
                  <td>{{ item.invoiceNumber }}</td>
                  <td>{{ item.seller?.name }}</td>
                  <td>{{ item.sponsorName }}</td>
                  <td>{{ item.sponsorGovernmentId }}</td>
                  <!-- Valor total -->
                  <td><span v-money-format="item.paymentValue"></span></td>
                  <td><span v-date-format="item.paymentDate"></span></td>
                  <td>{{ item.totalInstallments }}</td>
                  <td>{{ item.installmentExternalId }}</td>
                  <td><span v-date-format="item.installmentDueDate"></span></td>
                  <td>{{ item.daysUntilDue }}</td>
                  <td><span v-money-format="item.installmentAmount"></span></td>
                  <td>
                    <span
                      v-money-format="item.preCalculatedAcquisitionPrice"
                    ></span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </v-container>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import kanastraApi from "../../../../libraries/kanastra/kanastraApi";
import AutocompleteSelect from "../../components/select/AutocompleteSelect.vue";
import dateFunctions from "../../../../libraries/corsolar/dateFunctions";
//import selectedInstallments from "./selectedInstallments29fev2024";
import DatePicker from "../../components/fields/DatePicker.vue";
import { T } from "lodash/fp";
import getNfeFromFileJson from "../../../../libraries/functions/getNfeFromFileJson";
export default {
  name: "installments",
  components: {
    AutocompleteSelect,
    DatePicker,
  },

  data() {
    return {
      offersToCheckShow: false,
      // selectedInstallments,
      //selectedInstallments: [],
      snackbarShow: false,
      snackbarColor: "green",
      snackbarText: "",
      filters: {
        chipSeller: "",
        offerExternalId: "",
        sponsorName: "",
        kanastraCreatedAtFrom: "",
      },
      isExpanded: false,
      dialogShow: false,
      sending: false,
      notifications: false,
      sound: true,
      widgets: false,
      files: [],
      jsonData: [],
      headers: ["PrestadorRazaoSocial", "DataEmissao"],
      variables: [],
      monthlyInterest: 0,
      monthlyInterestEdit: 0,
      loadingInstallments: false,
      headers: [
        {
          text: "Selecionar",
          align: "start",
          sortable: false,
          value: "select",
        },
        {
          text: "Offer External Id",
          align: "start",
          sortable: false,
          value: "offerExternalId",
        },
        {
          text: "Nota fiscal",
          align: "start",
          sortable: false,
          value: "invoiceNumber",
        },
        {
          text: "Seller",
          align: "start",
          sortable: false,
          value: "seller",
        },
        {
          text: "Sponsor",
          align: "start",
          sortable: false,
          value: "sponsorName",
        },
        {
          text: "Sponsor Doc.",
          align: "start",
          sortable: false,
          value: "sponsorGovernmentId",
        },

        {
          text: "Valor total (R$)",
          align: "start",
          sortable: false,
          value: "paymentValue",
        },
        {
          text: "Data do pagamento",
          align: "start",
          sortable: false,
          value: "paymentDate",
        },
        {
          text: "Parcelas",
          align: "start",
          sortable: false,
          value: "totalInstallments",
        },
        {
          text: "Id da parcela",
          align: "start",
          sortable: false,
          value: "installmentExternalId",
        },

        {
          text: "Data de vencimento",
          align: "start",
          sortable: false,
          value: "installmentDueDate",
        },
        {
          text: "Prazo dias",
          align: "start",
          sortable: false,
          value: "daysUntilDue",
        },

        {
          text: "Valor(R$)",

          align: "start",
          sortable: false,
          value: "installmentAmount",
        },

        {
          text: "Valor arquisição(R$)",

          align: "start",
          sortable: false,
          value: "preCalculatedAcquisitionPrice",
        },
        {
          text: "Status envio",

          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "Arquivo disponível",
          align: "start",
          sortable: false,
          value: "hasFile",
        },
      ],

      installments: [],

      sortBy: "paymentDate",
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      providers: [],
      chipProvider: "",
      sellers: [],
      maxAverageDaysUntilDue: 70,
      acquisitions: [],
      offersToCheck: [],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      vuexSelectedIntallments: (state) => state.selectedInstallments,
    }),

    selectedInstallments: {
      get() {
        console.log("get selectedInstallments");
        return this.vuexSelectedIntallments;
      },
      set(value) {
        console.log("set selectedInstallments");
        this.setSelectedInstallments(value);
      },
    },

    installmentsWithNoFile() {
      return this.selectedInstallments.filter((el) => !el?.offer?.fileContent);
    },

    // installmentsWithNoFile = installments.filter(
    //     (el) => !el?.offer?.fileContent
    //   );
    conexosUrl() {
      return process.env.VUE_APP_STRAPI_URL;
    },

    // infoFromFileJson() {
    //   return this.installments.map((el) => ({
    //     fileJson: el.offer?.fileJson,
    //     sellerName: getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.emit?.xNome,
    //     sellerGovernmentId:
    //       getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.emit?.CNPJ,

    //     sponsorName: getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.xNome,
    //     sponsorGovernmentId: this.getDestDoc(el),
    //   }));
    // },

    averageDaysUntilDue() {
      if (this.selectedInstallments?.length == 0) {
        return 0;
      }
      let sumDays = this.selectedInstallments.reduce(
        (acc, curr) =>
          acc + curr.daysUntilDue * curr.preCalculatedAcquisitionPrice,
        0
      );

      let sumPrice = this.selectedInstallments.reduce(
        (acc, curr) => acc + curr.preCalculatedAcquisitionPrice,
        0
      );

      return Math.round(sumDays / sumPrice);

      //return 12;
    },
    sellerNames() {
      return this.sellers.map((el) => el.name);
    },

    filterChipSeller() {
      return this.filters?.chipSeller;
    },

    providersNames() {
      return this.providers?.map((el) => el.dpeNomPessoa.substring(0, 20));
    },

    provider() {
      return this.providers?.find((el) =>
        el.dpeNomPessoa.includes(this.chipProvider)
      );
    },
  },

  watch: {
    chipProvider(val, oldVal) {
      this.doSearch();
    },

    // async filterChipSeller(val, oldVal) {
    //   //alert("changed");
    //   //alert(val);
    //   this.selectedInstallments = [];
    //   await this.doSearch();
    // },
  },
  async mounted() {
    await this.getVariables();
    await this.getSellers();
  },
  methods: {
    ...mapMutations({
      setSelectedInstallments: "SET_SELECTED_INSTALLMENTS",
    }),

    getDestDoc(installment) {
      return (
        getNfeFromFileJson(installment.offer?.fileJson)?.infNFe?.dest?.CNPJ ??
        getNfeFromFileJson(installment.offer?.fileJson)?.infNFe?.dest?.CPF
      );
    },

    //selectedInstallments
    totalPrice(installments) {
      return installments?.reduce(
        (acc, curr) => acc + curr.installmentAmount,
        0
      );
    },
    totalAcquisitionPrice(installments) {
      return installments?.reduce(
        (acc, curr) => acc + curr.preCalculatedAcquisitionPrice,
        0
      );
    },

    customRowClass(item) {
      // Determine the conditions and return appropriate class
      if (this.isSelected(item)) {
        return "row-class-1"; // Class for rows with age greater than 30
      } else {
        return "row-class-2"; // Class for other rows
      }
    },
    isSelected(item) {
      console.log("isSelected");
      let foundItem = this.selectedInstallments.find((el) => el.id == item.id);
      return foundItem ? true : false;
    },
    rowClicked(item) {
      console.log("rowClicked");
      if (!this.isSelected(item)) {
        console.log("unselect");

        this.selectedInstallments = [...this.selectedInstallments, item];
        //this.selectedInstallments.push(item);
      } else {
        console.log("isSelect");
        this.selectedInstallments = this.selectedInstallments.filter(
          (el) => el.id != item.id
        );
      }
    },

    async editMonthlyInterest() {
      let value = this.parseFloatMoney(this.monthlyInterestEdit);
      let variable = this.variables.find((el) => el.name == "monthlyInterest");

      if (variable) {
        let dataUpdate = { id: variable.id, value };
        //alert(`editMonthlyInterest:${JSON.stringify({ value })}`);
        let ans = await strapiApi.variables.put(dataUpdate);

        //alert(JSON.stringify({ ans }));
        if (ans?.value) {
          this.monthlyInterest = ans?.value;
        } else {
          alert("error");
          this.monthlyInterestEdit = this.monthlyInterest.toFixed(2);
        }
      }
    },

    async getVariables() {
      let variables = await strapiApi.variables.get();

      this.variables = variables;
      // alert(JSON.stringify({ variables }));

      let monthlyInterestVar = variables.find(
        (el) => el.name == "monthlyInterest"
      );
      //alert(JSON.stringify({ monthlyInterestVar }));

      if (monthlyInterestVar) {
        this.monthlyInterest = monthlyInterestVar.value;
        this.monthlyInterestEdit = monthlyInterestVar.value.toFixed(2);
      }
    },
    parseFloatMoney(text) {
      console.log(`ParseFloat:${text}`);
      text = text.replace(/\./g, "");
      text = text.replace(",", ".");
      console.log(`ParseFloat:${text}`);
      return parseFloat(text);
    },

    toggleExpand() {
      //alert("toggle");
      this.isExpanded = !this.isExpanded;
    },
    updated(item, value) {
      //alert(`Updated:${JSON.stringify({ id, value })}`);
      if (value) {
        // this.selectedInstallments.push(item);
        this.selectedInstallments = [...this.selectedInstallments, item];
      } else {
        this.selectedInstallments = this.selectedInstallments.filter(
          (el) => el.id != item.id
        );
      }
    },
    remove(item) {
      //alert(`Updated:${JSON.stringify({ id, value })}`);

      this.selectedInstallments = this.selectedInstallments.filter(
        (el) => el.id != item.id
      );
    },

    async getSellers() {
      let { values, pagination } = await strapiApi.sellers.getWithPagination(
        1,
        25,
        "id",
        false
      );

      this.sellers = [{ id: 0, name: "TODOS" }, ...values];
      this.filters.chipSeller = "TODOS";
      //his.sellers.push({ id: 0, name: "TODOS" });
      //this.filters.chipSeller = values?.[0]?.name;
      this.doSearch();
    },

    async kanastraAuth() {
      let ans = await kanastraApi.auth();

      alert(JSON.stringify({ ans }));
    },
    async donwloadJson(jsonData, filename) {
      // Create a Blob from the JSON data
      const blob = new Blob([JSON.stringify(jsonData)], {
        type: "application/json",
      });

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set link's href to the URL of the Blob
      link.href = url;

      // Set the filename for the download
      link.download = filename;

      // Append the link to the document body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);

      // Release the object URL
      URL.revokeObjectURL(url);
    },

    closeAllDialogs() {
      this.isExpanded = false;
      this.dialogShow = false;
      this.sending = false;
    },

    async sendinstallmentsBackend() {
      this.sending = true;
      console.log("send installments");
      console.log(JSON.stringify(this.selectedInstallments));
      let installments = this.selectedInstallments;
      let installmentsWithNoFile = installments.filter(
        (el) => !el?.offer?.fileContent
      );
      if (installmentsWithNoFile?.length > 0) {
        this.donwloadJson(installmentsWithNoFile, "OffersWithNoXml.json");
        this.snackbarColor = "red";
        this.snackbarText = `Um ítem ou mais sem arquivo XML, favor enviar o arquivo ao TI`;
        this.snackbarShow = true;
        this.sending = false;
        return;
      }

      let sellers = installments.map((el) => ({
        ...this.sellers[1], //Default Cordeiro

        ...this.sellers?.find(
          (item) => item.governmentId == el.seller?.governmentId
        ),
        name: getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.emit?.xNome,
        governmentId: getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.emit
          ?.CNPJ,
      }));

      sellers = sellers.filter(
        (arr, index, self) =>
          index === self.findIndex((t) => t.governmentId === arr.governmentId)
      );

      let acquisitions = [];
      for (let i = 0; i < sellers.length; i++) {
        let seller = sellers[i];
        acquisitions.push(this.formatDataToSend(seller, installments));
      }

      this.acquisitions = acquisitions;
      this.offersToCheck = this.getOffersToCheck(acquisitions);

      if (this.offersToCheck?.length > 0) {
        this.offersToCheckShow = true;
      } else {
        let ans;
        ans = await strapiApi.installments.sendManyBatch(acquisitions);

        this.selectedInstallments = [];
        this.acquisitions = [];
        this.snackbarColor = "green";
        this.snackbarText = "Offers enviadas";
        this.snackbarShow = true;
      }

      this.closeAllDialogs();
    },

    async continueSendinstallmentsBackend() {
      this.sending = true;
      let acquisitions = this.acquisitions;
      let ans;
      ans = await strapiApi.installments.sendManyBatch(acquisitions);

      this.selectedInstallments = [];

      this.snackbarColor = "green";
      this.snackbarText = "Offers enviadas";
      this.snackbarShow = true;

      this.offersToCheckShow = false;
      this.offersToCheck = [];

      this.closeAllDialogs();
    },

    getOffersToCheck(acquisitions) {
      let offersToCheck = [];

      for (let a = 0; a < acquisitions?.length; a++) {
        let acquisition = acquisitions[a];
        for (let i = 0; i < acquisition?.items?.length; i++) {
          let item = acquisition.items[i];

          if (item?.oldOffers?.length) {
            offersToCheck = [...offersToCheck, item];
          }
        }
      }

      return offersToCheck;
    },

    formatDataToSend(seller, installments) {
      let data = {
        sellerId: seller.id,
        sellerName: seller.name,
        sellerPersonType: seller.personType,
        sellerGovernmentId: seller.governmentId,
        sellerExternalCode: seller.name,
        sellerAddress: seller.address,
        sellerAddressNumber: seller.addressNumber,
        sellerAddressComplement: seller.addressComplement,
        sellerNeighborhood: seller.neighborhood,
        sellerCity: seller.city,
        sellerState: seller.state,
        sellerCountry: seller.country,
        sellerZipCode: seller.zipCode,
        sellerBank: seller.bank,
        sellerAgency: seller.agency,
        sellerAgencyDigit: seller.agencyDigit,
        sellerAccount: seller.account,
        sellerAccountDigit: seller.accountDigit,
        coobrigation: false,
        customFields: {},
        // totalPrice: this.totalPrice(installments),
        // totalAcquisitionPrice: this.totalAcquisitionPrice(installments),
        totalPrice: this.totalPrice(
          installments.filter(
            (item) =>
              getNfeFromFileJson(item.offer?.fileJson)?.infNFe?.emit?.CNPJ ==
              seller.governmentId
          )
        ),
        totalAcquisitionPrice: this.totalAcquisitionPrice(
          installments.filter(
            (item) =>
              getNfeFromFileJson(item.offer?.fileJson)?.infNFe?.emit?.CNPJ ==
              seller.governmentId
          )
        ),
      };
      //console.log(JSON.stringify({ installments }));
      console.log("cnpj");
      console.log(
        installments.map(
          (el) => getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.emit?.CNPJ
        )
      );
      //Offers
      let offers = installments
        .filter(
          (item) =>
            getNfeFromFileJson(item.offer?.fileJson)?.infNFe?.emit?.CNPJ ==
            seller.governmentId
        )
        .map((el) => ({
          id: el.offer?.id,
          kanastraRequestCount: el.offer.kanastraRequestCount,
          fileContent: el.offer?.fileContent,
          invoiceKey: el.offer?.invoiceKey,
          offerExternalId: el.offerExternalId,
          sponsorName:
            getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.xNome ??
            el.sponsorName,
          sponsorPersonType: el.sponsorPersonType,
          sponsorGovernmentId: this.getDestDoc(el) ?? el.sponsorGovernmentId,
          sponsorExternalCode: el.sponsorName,
          sponsorAddress:
            getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.enderDest
              ?.xLgr ?? el.sponsorAddress,
          sponsorAddressNumber:
            getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.enderDest
              ?.nro ?? el.sponsorAddressNumber,
          sponsorAddressComplement: el.sponsorAddressComplement,
          sponsorNeighborhood:
            getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.enderDest
              ?.xBairro ?? el.sponsorNeighborhood,
          sponsorCity:
            getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.enderDest
              ?.xMun ?? el.sponsorCity,
          sponsorState:
            getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.enderDest
              ?.UF ?? el.sponsorState,
          sponsorCountry:
            getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.enderDest
              ?.cPais ?? el.sponsorCountry,
          sponsorZipCode:
            getNfeFromFileJson(el.offer?.fileJson)?.infNFe?.dest?.enderDest
              ?.CEP ?? el.sponsorZipCode,
          sponsorBank: el.sponsorBank,
          sponsorAgency: el.sponsorAgency,
          sponsorAgencyDigit: el.sponsorAgencyDigit,
          sponsorAccount: el.sponsorAccount,
          sponsorAccountDigit: el.sponsorAccountDigit,
          invoiceNumber: el.invoiceNumber,
          invoiceDate: el.invoiceDate?.substring(0, 10).replace(/-/g, ""),
          updateNumber: el.offer?.updateNumber,
          invoiceKey:
            getNfeFromFileJson(el?.offer?.fileJson)?.Signature?.SignedInfo
              ?.CanonicalizationMethod?.Transform?.protNFe?.infProt?.chNFe ??
            el.invoiceKey ??
            el.offer.invoiceKey,
          totalInstallments: el.totalInstallments, //Calcular pelo banco de dados
          paymentValue: el.paymentValue,
          paymentDate: el.paymentDate?.substring(0, 10).replace(/-/g, ""),
          assetType: el.assetType ?? "DUPLICATA_MERCANTIL", //Test 31/01/24
          customFields: {},
          installments: el.offer.installments,
          kanastraOffers: el.offer.kanastraOffers,
        }));

      offers = offers.filter(
        (arr, index, self) => index === self.findIndex((t) => t.id === arr.id)
      );

      console.log(JSON.stringify({ offers }));

      let items = offers.map((item) => ({
        offerInstallments: item.installments,
        oldOffers: item.kanastraOffers,
        updateNumber: item.updateNumber,
        offerId: item.id,
        externalId: this.getOfferExternalId(seller, item),
        kanastraRequestCount: item.kanastraRequestCount,
        sponsorName: item.sponsorName,
        sponsorGovernmentId: item.sponsorGovernmentId,
        sponsorPersonType: this.getPersonType(item.sponsorGovernmentId),
        sponsorExternalCode: item.sponsorName,
        sponsorAddress: item.sponsorAddress,
        sponsorAddressNumber: item.sponsorAddressNumber,
        sponsorAddressComplement: item.sponsorAddressComplement,
        sponsorNeighborhood: item.sponsorNeighborhood,
        sponsorCity: item.sponsorCity,
        sponsorState: item.sponsorState,
        sponsorCountry: item.sponsorCountry,
        sponsorZipCode: item.sponsorZipCode,
        sponsorBank: item.sponsorBank,
        sponsorAgency: item.sponsorAgency,
        sponsorAgencyDigit: item.sponsorAgencyDigit,
        sponsorAccount: item.sponsorAccount,
        sponsorAccountDigit: item.sponsorAccountDigit,
        invoiceNumber: item.invoiceNumber,
        invoiceDate: item.invoiceDate?.substring(0, 10).replace(/-/g, ""),
        invoiceKey: item.invoiceKey,
        assetType: item.assetType ?? "NOTA_FISCAL",
        customFields: {},

        files: [
          {
            content: item.fileContent,
            category: "nfe_xml",
            name: `${item.invoiceKey}.xml`,
          },
        ],
        totalInstallments: installments.filter(
          (installment) => installment.offer?.id == item.id
        ).length,
        paymentValue: this.getPaymentValue(
          installments.filter((installment) => installment.offer?.id == item.id)
        ),
        paymentDate: this.getPaymentDate(
          installments.filter((installment) => installment.offer?.id == item.id)
        ),

        installments: installments
          .filter((installment) => installment.offer?.id == item.id)
          .map((el, index) => ({
            installmentId: el.id,
            externalId: this.getOfferInstallmentId(
              el.installmentExternalId,
              item,
              el,
              seller
            ),
            amount: el.installmentAmount,
            dueDate: el.installmentDueDate?.substring(0, 10).replace(/-/g, ""),
            customFields: {
              preCalculatedAcquisitionPrice: el.preCalculatedAcquisitionPrice,
            },
          })),
      }));

      data.items = items;

      // data.totalPrice = items.reduc

      return data;
    },

    getPersonType(governmentId) {
      if (governmentId?.length == 11) {
        return "NATURAL_PERSON";
      } else {
        return "LEGAL_PERSON";
      }
    },

    // getOfferExternalId(seller, offer) {
    //   let id = parseInt(seller.id);
    //   let count = offer.kanastraRequestCount ?? 0;
    //   if (id == 1) {
    //     return `${offer.offerExternalId}_${count}`;
    //   } else {
    //     let invoiceNumber = parseInt(offer.invoiceNumber);
    //     return `${id * 10 ** 8 + offer.invoiceNumber}_${count}`;
    //   }
    // },

    getOfferExternalId(seller, offer) {
      let prefix = seller?.prefix ?? seller?.id;
      let version = offer.updateNumber ?? 0;
      let invoiceNumber = offer.invoiceNumber;

      // let id = parseInt(seller.id);

      let count = offer.kanastraRequestCount ?? 0;

      return `${prefix}NF${invoiceNumber}V${version}.${count}`;
      // if (id == 1) {
      //   return `${offer.offerExternalId}_${count}`;
      // } else {
      //   let invoiceNumber = parseInt(offer.invoiceNumber);
      //   return `${id * 10 ** 8 + offer.invoiceNumber}_${count}`;
      // }
    },

    getOfferInstallmentId(externalId, offer, installment, seller) {
      let installments = offer?.installments;

      if (installments) {
        installments = this.sortByInstallmentDueDate(installments);
        let index =
          installments.findIndex((el) => el.id === installment.id) ?? 0;
        //return `${externalId}_${count}`;
        let prefix = seller?.prefix ?? seller?.id;
        let version = offer.updateNumber ?? 0;
        let invoiceNumber = offer.invoiceNumber;
        let count = offer.kanastraRequestCount ?? 0;

        return `${prefix}NF${invoiceNumber}P${index + 1}V${version}.${count}`;
        //alert(index);
      } else {
        let count = offer.kanastraRequestCount ?? 0;
        return `${externalId}${count}`;
      }
    },

    sortByInstallmentDueDate(arr) {
      return arr.sort((a, b) => {
        const dateA = new Date(a.installmentDueDate);
        const dateB = new Date(b.installmentDueDate);
        return dateA - dateB;
      });
    },

    getPaymentValue(installments) {
      return installments.reduce((acc, curr) => {
        return acc + curr.installmentAmount;
      }, 0);
    },

    getPaymentDate(installments) {
      installments.sort(
        (a, b) =>
          new Date(a.installmentDueDate).getTime() -
          new Date(b.installmentDueDate).getTime()
      );
      return installments[0]?.installmentDueDate
        ?.substring(0, 10)
        .replace(/-/g, "");
    },

    formatDate() {},

    async newPayment() {
      this.$router.push("/newPayment");
    },
    async newPaymentWithNfse(nfse) {
      this.$router.push(`/NewPaymentCom298FromNfse/${nfse?.id}`);
    },
    async updatePage() {
      await this.doSearch();
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },

    async doMainSearch() {
      this.page = 1;
      await this.doSearch();
    },

    async doSearch() {
      this.loadingInstallments = true;
      let seller = this.sellers?.find((el) =>
        el.name.includes(this.filters.chipSeller)
      );

      let { values, pagination } =
        await strapiApi.installments.getWithPagination(
          this.page,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc,
          seller?.id,
          this.filters.sponsorName,
          this.filters.offerExternalId,
          false
        );
      let installments = values.map((value) => ({
        ...value,
        daysUntilDue: this.calcDaysUntilDue(value.installmentDueDate),
        preCalculatedAcquisitionPrice: this.calcPreCalculatedAcquisitionPrice(
          value.installmentAmount,
          this.calcDaysUntilDue(value.installmentDueDate),
          this.monthlyInterest / (30 * 100)
        ),

        selected: this.selectedInstallments.find((el) => el.id == value.id)
          ? true
          : false,
      }));

      this.serverItemsLength = pagination?.total;

      this.installments = installments;

      this.loadingInstallments = false;
    },

    calcDaysUntilDue(date) {
      let d = new Date(date);
      let today = new Date();

      let miliseconds = d.getTime() - today.getTime();

      return Math.ceil(miliseconds / (24 * 60 * 60 * 1000));
    },

    calcPreCalculatedAcquisitionPrice(value, days, dailyInterest) {
      // console.log(dailyInterest);
      // return value * (1 - dailyInterest) ** days;
      const diff = value * dailyInterest * days;
      return value - diff;
    },
  },
};
</script>

<style scoped>
.red {
  color: black;
  background-color: red;
}

.divExpanded {
  height: 80%;
}
.divCollapsed {
  height: 120px;
}

.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
.row-class-1 {
  background-color: lightcoral; /* Example styling */
}
.row-class-2 {
  background-color: lightgreen; /* Example styling */
}
</style>
